import { PaletteOptions } from '@mui/material';

type CustomPalette = {
  primary: string;
  secondary: string;
};

// Update the Typography's variant prop options
declare module '@mui/material' {
  interface Palette {
    beige: Palette['primary'];
    tableRow: CustomPalette;
    border: CustomPalette;
  }

  interface PaletteOptions {
    beige: Palette['primary'];
    tableRow: Palette['tableRow'];
    border: Palette['border'];
  }
}

// Will create tests for the future for when after we talk more with design to inify the look and feel.
const palette = {
  primary: {
    main: '#080A2D',
    light: '#FFF',
    dark: '#FFF',
    contrastText: '#000',
  },
  secondary: {
    main: '#3DB157',
    light: 'rgba(61, 177, 87, 0.1)',
    dark: 'rgba(61, 177, 87, 0.2)',
  },
  text: {
    primary: '#1D1D1D',
    secondary: '#777777',
    disabled: '#8D8D8D',
  },
  action: {
    disabledBackground: 'rgba(8, 10, 45, 0.04)',
    hover: 'rgb(242 249 243)',
  },
  error: {
    main: '#FF3030',
  },
  grey: {
    50: '#080A2D14',
    100: '#1D1D1D',
    200: '#858693',
    400: '#7D7F90',
    600: 'rgba(8, 10, 45, 0.12)',
  },
  beige: {
    main: '#F3CF99',
    light: '#F3CF99',
    dark: '#F3CF99',
    contrastText: '#1D1D1D',
  },
  tableRow: {
    primary: '#ebebf0',
    secondary: '#fff',
  },
  border: {
    primary: '#1D1D1D',
    secondary: 'rgba(8, 10, 45, 0.3)',
  },
} satisfies PaletteOptions;

const riPalette = {
  text: {
    secondary: '#595959',
  },
} satisfies Pick<PaletteOptions, 'text'>;

export { palette as default, riPalette };
