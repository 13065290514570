import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/material/styles';

type Props = {
  isExportButtonDisabled: boolean;
  onExport: () => void;
};

const ButtonStyled = styled(Button)(({ theme }) => ({
  background: 'white',
  border: `1px solid ${theme.palette.text.primary}`,
  padding: '16px 18px',
}));

export function ExportButtonDropdown({ isExportButtonDisabled, onExport }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExport = () => {
    onExport();
    handleClose();
  };

  const buttonId = 'export-button-to-open-menu';
  const menuId = 'export-menu';

  return (
    <>
      <ButtonStyled
        id={buttonId}
        data-testid="table-export-btn"
        aria-controls={open ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Export
      </ButtonStyled>
      <Menu
        id={menuId}
        aria-labelledby={buttonId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          disabled={isExportButtonDisabled}
          onClick={handleExport}
          data-testid="table-export-current-rows-btn"
        >
          Current page
        </MenuItem>
      </Menu>
    </>
  );
}
