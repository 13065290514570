import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { riApplicationsTableColumns } from './ri-applications-table-columns';
import { useSearchParams } from 'react-router-dom';
import { useRiApplicationList } from '../../api';
import { RiListApplication } from '../../types';
import { getRiApplicationsTableColumnMeta, mergeSx } from '../../utils';
import { RiTableBody, RiTableHead, RiTableHeader, RiTableRoot, RiTableRow } from '../../components';
import { RiApplicationTableHeader } from './RiApplicationTableHeader';
import { RiApplicationsTableRow } from './RiApplicationsTableRow';
import { RiApplicationFooter } from './RiApplicationFooter';
import {
  parseFiltersFromParams,
  parsePaginationFromParams,
} from '../../utils/ri-application-params-parsers.util';
import { PaginationQueryParamKeys } from '@/features/RentersInsurance/constants';

export function RiApplicationsTable() {
  const [searchParams] = useSearchParams();
  const { currentLimit, currentPage } = parsePaginationFromParams({
    page: searchParams.get(PaginationQueryParamKeys.Page),
    limit: searchParams.get(PaginationQueryParamKeys.Limit),
  });

  const { search, source, complianceStatus, policyStatus } = parseFiltersFromParams(searchParams);

  const {
    data: listApplicationsData,
    status,
    fetchStatus,
  } = useRiApplicationList({
    limit: currentLimit,
    page: currentPage,
    search,
    source,
    complianceStatus,
    policyStatus,
  });

  const isInitialLoading = status === 'pending';
  const isDataLoading = isInitialLoading || fetchStatus === 'fetching';
  const isDataEmpty = listApplicationsData?.result.length === 0 && !isDataLoading;
  const isDataError = status === 'error' && !isDataLoading;

  const results = listApplicationsData?.result ?? [];

  const table = useReactTable<RiListApplication>({
    columns: riApplicationsTableColumns,
    data: results,
    getCoreRowModel: getCoreRowModel(),
    rowCount: listApplicationsData?.pagination.totalCount ?? 0,
    state: {
      pagination: {
        pageSize: currentLimit,
        pageIndex: currentPage - 1,
      },
    },
    manualPagination: true,
    sortingFns: undefined as never,
  });

  const tableHeaderGroups = table.getHeaderGroups();
  const tableRows = table.getRowModel().rows;

  return (
    <RiTableRoot
      layoutMode="fixed"
      slot={<RiApplicationTableHeader rowsToExport={results} isDataLoading={isDataLoading} />}
      footer={
        <RiApplicationFooter
          isInitialLoading={isInitialLoading}
          count={listApplicationsData?.pagination.totalCount ?? 0}
        />
      }
    >
      <RiTableHeader>
        {tableHeaderGroups.map((headerGroup) => {
          return (
            <RiTableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const columnMeta = getRiApplicationsTableColumnMeta(header);
                const headerWidth = header.column.columnDef.size ?? '100%';
                const headerSx = mergeSx(
                  {
                    width: headerWidth,
                  },
                  columnMeta.headerSx,
                );

                return (
                  <RiTableHead key={header.id} colSpan={header.colSpan} sx={headerSx}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </RiTableHead>
                );
              })}
            </RiTableRow>
          );
        })}
      </RiTableHeader>
      <RiTableBody
        isEmpty={isDataEmpty}
        isLoading={isDataLoading}
        isError={isDataError}
        loadingColumns={riApplicationsTableColumns.length}
        loadingRows={currentLimit}
      >
        {tableRows.map((row) => {
          return <RiApplicationsTableRow key={row.id} row={row} />;
        })}
      </RiTableBody>
    </RiTableRoot>
  );
}
