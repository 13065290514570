import { PaginationQueryParamKeys } from '@/features/RentersInsurance/constants';

const AllowedSearchParams = [
  'page',
  'limit',
  'search',
  'sortBy',
  'sortOrder',
  'complianceStatus',
  'policyStatus',
  'source',
] as const;

/**
 * Removes unwanted search parameters from a URL search string.
 * List of allowed search parameters is defined in `AllowedSearchParams` constant.
 * @param searchParams - The URL search string to be purged.
 * @returns The modified URL search string with unwanted parameters removed.
 */
export function purgeUrlSearchParams(searchParams: string) {
  const urlSearchParams = new URLSearchParams(searchParams);

  for (const key of urlSearchParams.keys()) {
    if (!AllowedSearchParams.includes(key as (typeof AllowedSearchParams)[number])) {
      urlSearchParams.delete(key);
    }
  }

  return urlSearchParams.toString();
}

export const resetPageSearchParam = (searchParams: URLSearchParams) => {
  searchParams.delete(PaginationQueryParamKeys.Page);

  return searchParams;
};
