import Alert, { alertClasses } from '@mui/material/Alert';
import palette from '@/theme/themePalette';
import Typography from '@mui/material/Typography';
import { MuiSxCollection } from '../../types';

interface RiApplicationDetailsErrorProps {
  error: Error;
}

const errorStyles = {
  root: {
    borderRadius: '8px',
    backgroundColor: 'transparent',
    border: `1px solid ${palette.error.main}`,
    boxShadow: '4px 16px 32px 0px #1D1D1D14',
    [`& .${alertClasses.message}`]: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '4px',
    },
  },
  text: {
    lineHeight: '26px',
  },
} satisfies MuiSxCollection;

export function RiApplicationDetailsError(_props: RiApplicationDetailsErrorProps) {
  return (
    <Alert severity="error" sx={errorStyles.root}>
      <Typography sx={errorStyles.text}>An error occurred</Typography>
      <Typography sx={errorStyles.text}>Reload the page and try again</Typography>
    </Alert>
  );
}
