import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material';
import { ReasonsNotCompliantIcon } from '../ReasonsNotCompliantIcon';
import {
  RiApplicationComplianceStatusEnum,
  RiApplicationComplianceStatusToTagMap,
} from '../../constants';
import { AppTheme, Except, MuiSxCollection } from '../../types';
import { ComponentPropsWithoutRef } from 'react';
import { mergeSx } from '../../utils';

const complianceStatusTagStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '6px',
  },
  dot: {
    borderRadius: '50%',
    width: '8px',
    height: '8px',
    display: 'inline-block',
    borderWidth: '2px',
    borderStyle: 'solid',
  },
  status: {
    lineHeight: '26px',
  },
} satisfies MuiSxCollection;

interface ComplianceStatusTagProps {
  value: RiApplicationComplianceStatusEnum;
  reasons: string[];
  showReasons?: boolean;
  sx?: SxProps<AppTheme>;
  statusTextProps?: Except<ComponentPropsWithoutRef<typeof Typography>, 'component' | 'children'>;
}

export function ComplianceStatusTag({
  value,
  reasons,
  showReasons = true,
  statusTextProps,
  sx,
}: ComplianceStatusTagProps) {
  const { name, color, borderColor } = getComplianceStatusStyle(value);
  const { sx: statusTextSx, ...statusTextPropsRest } = statusTextProps ?? {};

  const combinedTagSx = mergeSx(complianceStatusTagStyles.root, sx);
  const combinedStatusTextSx = mergeSx(complianceStatusTagStyles.status, statusTextSx);

  const displayReasons = value === RiApplicationComplianceStatusEnum.NotCompliant && showReasons;

  return (
    <Box data-testid="compliance-status-tag" component={'span'} sx={combinedTagSx}>
      <Box
        component={'span'}
        sx={complianceStatusTagStyles.dot}
        bgcolor={color}
        borderColor={borderColor}
      />
      <Typography component={'span'} sx={combinedStatusTextSx} {...statusTextPropsRest}>
        {name}
      </Typography>
      {displayReasons ? <ReasonsNotCompliantIcon reasons={reasons} /> : null}
    </Box>
  );
}

function getComplianceStatusStyle(value: RiApplicationComplianceStatusEnum) {
  const mapData = RiApplicationComplianceStatusToTagMap[value];

  if (!mapData) {
    console.warn(`No compliance status tag found for value: ${value}.`);

    return RiApplicationComplianceStatusToTagMap['in-progress'];
  }

  return mapData;
}
