import {
  RiApplicationSourceEnum,
  RiApplicationSourceQueryParamsEnum,
} from '@/features/RentersInsurance/constants';
import { ListRiApplicationsUrlParams } from '@/features/RentersInsurance/api';

export const mapRiApplicationSearchParams = (params: ListRiApplicationsUrlParams) => {
  const sourceParamArray = params.source || [];
  const sourceArray = sourceParamArray.map((sourceItem) =>
    sourceItem === RiApplicationSourceQueryParamsEnum.ThirdParty
      ? RiApplicationSourceEnum.Covie
      : RiApplicationSourceEnum.Msi,
  );

  return {
    ...params,
    source: sourceArray.length ? sourceArray : null,
  };
};
