import { useSearchParams } from 'react-router-dom';
import { trackEvent } from '@/utils/analytics';
import { RiSegmentEvents } from '../constants';
import { resetPageSearchParam } from '@/utils/purge-search-params.util';

type Option = { title: string; name: string };

type OptionWithSelected = Option & {
  selected: boolean;
};

export function useFilter(filterKey: string) {
  const [searchParams, setSearchParams] = useSearchParams();

  const getFilterOptions = (options: Option[]) => {
    const filterStr = searchParams.get(filterKey);

    if (!filterStr) {
      return options.map((option) => ({ ...option, selected: false }));
    }

    const filters = filterStr.split(',');

    return options.map((option) => ({
      ...option,
      selected: filters.includes(option.name),
    }));
  };

  const handleFilterChange = (options: OptionWithSelected[]) => {
    trackEvent(RiSegmentEvents.RiApplicationTableFilterChange, {
      filterKey,
      selectedOptions: options.filter((option) => option.selected),
    });

    setSearchParams((prevSearchParams) => {
      const selectedOptionNames = options
        .filter(({ selected }) => Boolean(selected))
        .map((option) => option.name);

      resetPageSearchParam(prevSearchParams);

      if (!selectedOptionNames.length) {
        prevSearchParams.delete(filterKey);

        return prevSearchParams;
      }

      prevSearchParams.set(filterKey, selectedOptionNames.join(','));

      return prevSearchParams;
    });
  };

  return {
    getFilterOptions,
    handleFilterChange,
  };
}
