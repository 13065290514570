import React from 'react';
import Typography from '@mui/material/Typography';
import { Skeleton } from '@mui/material';
import { RiTableFooter, RiTablePagination } from '../../components';
import { DefaultRiPagination, RiSegmentEvents } from '../../constants';
import { trackEvent } from '@/utils/analytics';
import { MuiSxCollection } from '../../types';
import { useSearchParams } from 'react-router-dom';
import { toInteger } from '../../utils';
import { resetPageSearchParam } from '@/utils/purge-search-params.util';

type Props = {
  count: number;
  isInitialLoading: boolean;
};

const riApplicationsTableStyles = {
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footerSearchResults: {
    fontSize: '14px',
    lineHeight: '24px',
  },
  paginationSkeleton: {
    marginRight: '16px',
  },
} satisfies MuiSxCollection;

export function RiApplicationFooter({ count, isInitialLoading }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = toInteger(searchParams.get('page'), {
    fallbackValue: DefaultRiPagination.Page,
  });

  const currentLimit = toInteger(searchParams.get('limit'), {
    fallbackValue: DefaultRiPagination.Limit,
  });

  function handlePaginate(page: number) {
    setSearchParams((params) => {
      if (page === DefaultRiPagination.Page) {
        params.delete('page');
      } else {
        params.set('page', `${page}`);
      }

      return params;
    });
  }

  function handleRowPerPageChange(limit: number) {
    trackEvent(RiSegmentEvents.RiApplicationsTableRowsPerPageChange, {
      limit,
    });

    setSearchParams((params) => {
      if (limit === DefaultRiPagination.Limit) {
        params.delete('limit');
      } else {
        params.set('limit', `${limit}`);
      }

      resetPageSearchParam(params);

      return params;
    });
  }

  return (
    <RiTableFooter sx={riApplicationsTableStyles.footer}>
      <Typography sx={riApplicationsTableStyles.footerSearchResults}>
        {isInitialLoading ? <Skeleton width={120} /> : `${count} search results`}
      </Typography>
      <RiTablePagination
        count={count}
        onPaginate={handlePaginate}
        rowsPerPageOptions={[10, 20, 50, 100]}
        rowsPerPage={currentLimit}
        onRowsPerPageChange={handleRowPerPageChange}
        page={currentPage}
        isLoading={isInitialLoading}
        skeletonProps={{ sx: riApplicationsTableStyles.paginationSkeleton }}
      />
    </RiTableFooter>
  );
}
