import { RiApplicationSourceEnum, DisplayedRiApplicationSourceEnum } from '../constants';
import { AppTheme, RiListApplication } from '../types';
import { SxProps } from '@mui/material';
import { Cell, Header } from '@tanstack/react-table';

export function mapRiApplicationSourceToDisplayedValue(source: RiApplicationSourceEnum) {
  return source === RiApplicationSourceEnum.Msi
    ? DisplayedRiApplicationSourceEnum.TheGuarantors
    : DisplayedRiApplicationSourceEnum.ThirdParty;
}

export interface RiApplicationsTableColumnMeta {
  cellSx?: SxProps<AppTheme>;
  headerSx?: SxProps<AppTheme>;
}
export function getRiApplicationsTableColumnMeta(
  data: Cell<RiListApplication, unknown> | Header<RiListApplication, unknown>,
) {
  return (data.column.columnDef.meta ?? {}) as RiApplicationsTableColumnMeta;
}
