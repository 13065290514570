import { toInteger } from '../utils';
import {
  DefaultRiPagination,
  RiApplicationComplianceStatusEnum,
  RiApplicationPolicyStatusEnum,
  RiApplicationSourceQueryParamsEnum,
} from '../constants';

type SearchParams = Record<string, string | null>;

export const parsePaginationFromParams = ({ page, limit }: SearchParams) => {
  const currentPage = toInteger(page, {
    fallbackValue: DefaultRiPagination.Page,
  });
  const currentLimit = toInteger(limit, {
    fallbackValue: DefaultRiPagination.Limit,
  });

  return { currentPage, currentLimit };
};

export const parseFiltersFromParams = (searchParams: URLSearchParams) => {
  const search = searchParams.get('search');
  const source = searchParams.get('source');
  const complianceStatus = searchParams.get('complianceStatus');
  const policyStatus = searchParams.get('policyStatus');

  const sourceArray = source?.split(',') as RiApplicationSourceQueryParamsEnum[] | undefined;
  const isSourceArrayValid = sourceArray?.every((sourceItem) =>
    Object.values(RiApplicationSourceQueryParamsEnum).includes(sourceItem),
  );

  const complianceStatusArray = complianceStatus?.split(',') as
    | RiApplicationComplianceStatusEnum[]
    | undefined;
  const isComplianceStatusValid = complianceStatusArray?.every((complianceStatusItem) =>
    Object.values(RiApplicationComplianceStatusEnum).includes(complianceStatusItem),
  );

  const policyStatusArray = policyStatus?.split(',') as RiApplicationPolicyStatusEnum[] | undefined;
  const isPolicyStatusValid = policyStatusArray?.every((policyStatusItem) =>
    Object.values(RiApplicationPolicyStatusEnum).includes(policyStatusItem),
  );

  return {
    search: search ?? undefined,
    source: isSourceArrayValid ? sourceArray : undefined,
    complianceStatus: isComplianceStatusValid ? complianceStatusArray : undefined,
    policyStatus: isPolicyStatusValid ? policyStatusArray : undefined,
  };
};
