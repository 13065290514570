import { MuiSxCollection } from '../../types';
import { getReasonsNotCompliantMessage } from '../../utils';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

interface ReasonsNotCompliantIconProps {
  reasons: string[];
}

const reasonsNotCompliantIconStyles = {
  tooltip: {
    whiteSpace: 'pre-line',
    width: '100%',
    fontSize: '10px',
    lineHeight: '14px',
    paddingY: '4px',
    paddingX: '8px',
  },
} satisfies MuiSxCollection;

export function ReasonsNotCompliantIcon({ reasons }: ReasonsNotCompliantIconProps) {
  const tooltipMessage = getReasonsNotCompliantMessage(reasons).join('\n');

  return (
    <Tooltip
      title={tooltipMessage}
      arrow={true}
      componentsProps={{
        tooltip: {
          sx: reasonsNotCompliantIconStyles.tooltip,
        },
      }}
      placement="top-start"
    >
      <InfoOutlined
        data-testid="reasons-not-compliant-icon"
        sx={{
          width: '20px',
          height: '20px',
        }}
      />
    </Tooltip>
  );
}
