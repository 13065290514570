import { mergeSx } from '../../utils';
import palette from '@/theme/themePalette';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { ComponentPropsWithoutRef } from 'react';

const valueStyles = {
  text: { fontSize: '14px', lineHeight: '24px' },
  error: {
    color: palette.error.main,
  },
};

type RiApplicationDetailsAddressValueProps = ComponentPropsWithoutRef<typeof Typography> & {
  isLoading: boolean;
  isReasonError: boolean;
  sketeletonProps?: ComponentPropsWithoutRef<typeof Skeleton>;
};
export function RiApplicationDetailsAddressValue({
  isLoading,
  isReasonError,
  sketeletonProps,
  children,
  sx,
  ...props
}: RiApplicationDetailsAddressValueProps) {
  if (isLoading) {
    return <Skeleton {...sketeletonProps} />;
  }

  const mergedSx = mergeSx(valueStyles.text, isReasonError && valueStyles.error, sx);

  return (
    <Typography sx={mergedSx} {...props}>
      {children}
    </Typography>
  );
}
