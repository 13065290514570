import { createColumnHelper } from '@tanstack/table-core';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ComplianceStatusTag } from '../';
import { RI_APPLICATION_TABLE_FALLBACK_VALUE, DateFormat } from '../../constants';
import { mapRiApplicationSourceToDisplayedValue, RiApplicationsTableColumnMeta } from '../../utils';
import { time } from '../../libs';
import { riPalette } from '@/theme/themePalette';
import { MuiSxCollection, RiListApplication } from '../../types';

const columnHelper = createColumnHelper<RiListApplication>();

const riApplicationsTableColumnsStyles = {
  buildingNameCell: {
    display: 'block',
    wordBreak: 'break-word',
  },
  renterCell: {
    display: 'flex',
    flexDirection: 'column',
  },
  renterCellName: {
    lineHeight: 'inherit',
    wordBreak: 'break-word',
  },
  renterCellEmail: {
    color: riPalette.text?.secondary,
    fontSize: '12px',
    lineHeight: '16px',
    wordBreak: 'break-word',
  },
  linkTypeCell: {
    textTransform: 'capitalize',
  },
} satisfies MuiSxCollection;

export const riApplicationsTableColumns = [
  columnHelper.accessor('address.addressName', {
    header: 'Building name',
    cell: (row) => {
      const rowValue = row.cell.getValue() ?? RI_APPLICATION_TABLE_FALLBACK_VALUE;

      return (
        <Typography component={'span'} sx={riApplicationsTableColumnsStyles.buildingNameCell}>
          {rowValue}
        </Typography>
      );
    },
    meta: {
      headerSx(theme) {
        const smalledLaptopBreakpoint = theme.breakpoints.down(1260);
        const xlFixedWidthBreakpoint = theme.breakpoints.between('xl', 1635);

        return {
          width: 250,
          [smalledLaptopBreakpoint]: {
            width: 200,
          },
          [xlFixedWidthBreakpoint]: {
            width: 180,
          },
        };
      },
    } satisfies RiApplicationsTableColumnMeta,
    size: undefined,
  }),
  columnHelper.accessor('buildingUnit', {
    header: 'Unit',
    cell: (row) => {
      const rowValue = row.cell.getValue() ?? RI_APPLICATION_TABLE_FALLBACK_VALUE;

      return <Typography component={'span'}>{rowValue}</Typography>;
    },
    size: 55,
  }),
  columnHelper.accessor(
    (row) => {
      return {
        firstName: row.firstName,
        lastName: row.lastName,
        email: row.email,
      };
    },
    {
      header: 'Renter',
      cell: (row) => {
        return (
          <Box sx={riApplicationsTableColumnsStyles.renterCell}>
            <Typography component={'span'} sx={riApplicationsTableColumnsStyles.renterCellName}>
              {row.cell.getValue().firstName} {row.cell.getValue().lastName}
            </Typography>
            <Typography component={'span'} sx={riApplicationsTableColumnsStyles.renterCellEmail}>
              {row.cell.getValue().email}
            </Typography>
          </Box>
        );
      },
      meta: {
        cellSx: {
          padding: {
            paddingTop: '8px',
            paddingBottom: '10px',
            paddingLeft: '16px',
            paddingRight: '24px',
          },
        },
        headerSx: (theme) => {
          const fixedWidthBreakpoint = theme.breakpoints.down(1345);
          const xlFixedWidthBreakpoint = theme.breakpoints.between('xl', 1635);

          return {
            [fixedWidthBreakpoint]: {
              width: 215,
            },
            [xlFixedWidthBreakpoint]: {
              width: 200,
            },
          };
        },
      } satisfies RiApplicationsTableColumnMeta,
      size: undefined,
    },
  ),
  columnHelper.accessor('source', {
    header: 'Policy type',
    cell: (row) => {
      return mapRiApplicationSourceToDisplayedValue(row.cell.getValue());
    },
    size: 160,
  }),
  columnHelper.accessor('updatedAt', {
    header: 'Last updated',
    cell: (row) => {
      return time(row.cell.getValue(), DateFormat.Timestamp).format(DateFormat.Us);
    },
    size: 144,
  }),
  columnHelper.accessor(
    (row) => {
      return {
        complianceStatus: row.complianceStatus,
        reasonsNotCompliant: row.reasonsNotCompliant,
      };
    },
    {
      header: 'Status',
      cell: (row) => {
        const { complianceStatus, reasonsNotCompliant } = row.cell.getValue();

        return <ComplianceStatusTag value={complianceStatus} reasons={reasonsNotCompliant} />;
      },
      size: 163,
    },
  ),
];
