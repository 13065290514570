import useFeatureflagStore from '@/stores/featureflagStore';

const isFeatureFlagEnabled = (): boolean => {
  const featureFlags = useFeatureflagStore.getState().featureFlags;
  return featureFlags.enable_new_renters_insurance_dashboard;
};

/**
 * @todo
 * Implement this function.
 *
 * Implementation details pending. Currently, just returning true.
 * This function should return true if the user has access to the RI dashboard.
 * @see
 * https://theguarantors.atlassian.net/browse/TIGER-1300
 */
const isUserGroupEnabled = (): boolean => {
  return true;
};

export const isRiDashboardEnabled = (): boolean => {
  const isFFOn = isFeatureFlagEnabled();
  const isUserGroupAllowed = isUserGroupEnabled();
  return isFFOn && isUserGroupAllowed;
};
