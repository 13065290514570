import { capitalize } from '@mui/material/utils';
import {
  Implements,
  Maybe,
  Nullable,
  ObjectKeys,
  RiApplicationAddress,
  RiDetailedApplication,
  RiDetailedApplicationAdditionalInsuredRoommate,
  RiDetailedApplicationAdditionalInterest,
  StringUnion,
} from '../types';
import {
  DateFormat,
  RI_DETAILED_APPLICATION_FALLBACK_VALUE,
  RiCoverageToLabelMap,
} from '../constants';
import { getCoverageReasonError, toCurrency } from '../utils';
import { time } from '../libs';

function parseRiApplicationDetails(application: RiDetailedApplication | undefined) {
  const mappedAddress = parseRiApplicationDetailsAddress(application?.address ?? null);
  const parsedCoverages = parseRiApplicationCoverages(application ?? null);
  const renterFullname = parseRiApplicationFullname(application?.firstName, application?.lastName);
  const additionalInsuredRoommates = parseRiApplicationAdditionalParties(
    application?.additionalInsuredRoommates,
  );
  const additionalInterests = parseRiApplicationAdditionalParties(application?.additionalInterests);

  const parsedDetails = {
    id: application?.id ?? null,
    address: mappedAddress,
    additionalInsuredRoommates,
    additionalInterests,
    buildingId: application?.buildingId ?? null,
    buildingUnit: parseRiApplicationBuildingUnit(application?.buildingUnit),
    complianceStatus: application?.complianceStatus ?? null,
    createdAt: application?.createdAt ?? null,
    coverages: parsedCoverages,
    email: parseRiApplicationStringValue(application?.email),
    renterFullname,
    phone: parseRiApplicationStringValue(application?.phone),
    policyCarrierProvider: parseRiApplicationStringValue(application?.policyCarrierProvider),
    policyEffectiveDate: parseRiApplicationPolicyDate(application?.policyEffectiveDate),
    policyExpirationDate: parseRiApplicationPolicyDate(application?.policyExpirationDate),
    policyNumber: parseRiApplicationStringValue(application?.policyNumber),
    policyStatus: capitalize(parseRiApplicationStringValue(application?.policyStatus)),
    reasonsNotCompliant: application?.reasonsNotCompliant ?? [],
    source: parseRiApplicationStringValue(application?.source),
    updatedAt: application?.updatedAt ?? null,
  };

  return parsedDetails;
}

type ParsedRiApplicationAddress = Implements<
  Record<ObjectKeys<RiApplicationAddress>, unknown>,
  {
    id: Nullable<number>;
    addressName: string;
    addressStreet: string;
    addressCity: string;
    addressState: string;
    displayedLine1: string;
    displayedLine2: string;
    zipCode: string;
    landlordCompany: string;
    llc: string;
    totalUnits: Nullable<number>;
    yearBuilt: Nullable<number>;
    assigned: Nullable<boolean>;
    riEnabled: Nullable<boolean>;
  }
>;
function parseRiApplicationDetailsAddress(
  address: RiApplicationAddress | null,
): ParsedRiApplicationAddress {
  if (!address) {
    return {
      id: null,
      addressName: RI_DETAILED_APPLICATION_FALLBACK_VALUE,
      addressState: RI_DETAILED_APPLICATION_FALLBACK_VALUE,
      addressStreet: RI_DETAILED_APPLICATION_FALLBACK_VALUE,
      landlordCompany: RI_DETAILED_APPLICATION_FALLBACK_VALUE,
      llc: RI_DETAILED_APPLICATION_FALLBACK_VALUE,
      addressCity: RI_DETAILED_APPLICATION_FALLBACK_VALUE,
      zipCode: RI_DETAILED_APPLICATION_FALLBACK_VALUE,
      displayedLine1: RI_DETAILED_APPLICATION_FALLBACK_VALUE,
      displayedLine2: RI_DETAILED_APPLICATION_FALLBACK_VALUE,
      assigned: null,
      riEnabled: null,
      totalUnits: null,
      yearBuilt: null,
    };
  }

  const parsedAddressStreet = parseRiApplicationStringValue(address.addressStreet);
  const parsedAddressCity = parseRiApplicationStringValue(address.addressCity);
  const parsedAddressState = parseRiApplicationStringValue(address.addressState);
  const parsedZipCode = parseRiApplicationStringValue(address.zipCode);
  const parsedLine2 = `${parsedAddressCity}, ${parsedAddressState} ${parsedZipCode}`;
  const displayedLine2 =
    parsedLine2 ===
    `${RI_DETAILED_APPLICATION_FALLBACK_VALUE}, ${RI_DETAILED_APPLICATION_FALLBACK_VALUE} ${RI_DETAILED_APPLICATION_FALLBACK_VALUE}`
      ? RI_DETAILED_APPLICATION_FALLBACK_VALUE
      : parsedLine2;

  return {
    id: address.id,
    addressName: parseRiApplicationStringValue(address.addressName),
    addressState: parseRiApplicationStringValue(address.addressState),
    addressStreet: parsedAddressStreet,
    assigned: address.assigned,
    landlordCompany: parseRiApplicationStringValue(address.landlordCompany),
    llc: parseRiApplicationStringValue(address.llc),
    totalUnits: address.totalUnits,
    yearBuilt: address.yearBuilt,
    addressCity: parseRiApplicationStringValue(address.addressCity),
    displayedLine1: parsedAddressStreet,
    displayedLine2: displayedLine2,
    riEnabled: address.riEnabled,
    zipCode: parsedZipCode,
  };
}

interface RiCoverageListItem {
  label: (typeof RiCoverageToLabelMap)[keyof typeof RiCoverageToLabelMap];
  value: typeof RI_DETAILED_APPLICATION_FALLBACK_VALUE | StringUnion;
  key: string;
  /** boolean value to determine if the coverage has an error from reasonsNotCompliant */
  hasReasonError: boolean;
  /** boolean value to determine if the coverage should be showed when it is null */
  isVisibleEmpty: boolean;
}
function parseRiApplicationCoverages(
  application: RiDetailedApplication | null,
): RiCoverageListItem[] {
  /**
   * Order of the coverages is important, it should match the order of the coverages in the UI
   * Please, do not change the order of the coverages
   * If you need to add a new coverage, please consult with the design team
   */
  const applicationCoverages = {
    personalLiabilityCoverage: application?.personalLiabilityCoverage ?? null,
    personalPropertyCoverage: application?.personalPropertyCoverage ?? null,
    lossOfUse: application?.lossOfUse ?? null,
    medicalPayments: application?.medicalPayments ?? null,
    waterBackup: application?.waterBackup ?? null,
    petDamage: application?.petDamage ?? null,
    deductible: application?.deductible ?? null,
    dwellingCoverage: application?.dwellingCoverage ?? null,
    theft: application?.theft ?? null,
    earthquake: application?.earthquake ?? null,
    hurricane: application?.hurricane ?? null,
    windHail: application?.windHail ?? null,
    identityFraud: application?.identityFraud ?? null,
    sewerBackupCoverage: application?.sewerBackupCoverage ?? null,
  };
  const applicationReasonsNotCompliant = application?.reasonsNotCompliant ?? [];

  const mappedCoverages = Object.entries(applicationCoverages).reduce<RiCoverageListItem[]>(
    (acc, [coverageKey, coverageValue]) => {
      const coverageLabel = RiCoverageToLabelMap[coverageKey as keyof typeof RiCoverageToLabelMap];

      if (coverageValue === undefined) {
        console.warn(`Coverage ${coverageKey} is not defined in the mapping`);

        return acc;
      }

      const mappedCoverageValue = parseRiApplicationCoverageValue(coverageValue);
      const hasReasonError = getCoverageReasonError(coverageKey, applicationReasonsNotCompliant);

      acc.push({
        label: coverageLabel,
        value: mappedCoverageValue,
        key: coverageKey,
        hasReasonError: hasReasonError,
        isVisibleEmpty: true,
      });

      return acc;
    },
    [],
  );

  return mappedCoverages;
}

function parseRiApplicationCoverageValue(coverageValue: number | null): string {
  return typeof coverageValue === 'number'
    ? toCurrency(coverageValue)
    : RI_DETAILED_APPLICATION_FALLBACK_VALUE;
}

function parseRiApplicationFullname(firstName: Maybe<string>, lastName: Maybe<string>): string {
  const parsedFirstName = parseRiApplicationStringValue(firstName);
  const parsedLastName = parseRiApplicationStringValue(lastName);

  if (
    parsedFirstName === RI_DETAILED_APPLICATION_FALLBACK_VALUE &&
    parsedLastName === RI_DETAILED_APPLICATION_FALLBACK_VALUE
  ) {
    return RI_DETAILED_APPLICATION_FALLBACK_VALUE;
  }

  return `${parsedFirstName} ${parsedLastName}`;
}

function parseRiApplicationStringValue<T>(
  value: T,
): NonNullable<T> | typeof RI_DETAILED_APPLICATION_FALLBACK_VALUE {
  if (!value) {
    return RI_DETAILED_APPLICATION_FALLBACK_VALUE;
  }

  return value;
}

type AdditionalParties =
  | RiDetailedApplicationAdditionalInsuredRoommate
  | RiDetailedApplicationAdditionalInterest;
type ParsedAdditionalParty = {
  key: string | number;
  fullName: string;
  email: string;
};
function parseRiApplicationAdditionalParties(
  additionalParties: Maybe<AdditionalParties[]>,
): ParsedAdditionalParty[] {
  const fallbackParties = [
    {
      key: 0,
      fullName: RI_DETAILED_APPLICATION_FALLBACK_VALUE,
      email: RI_DETAILED_APPLICATION_FALLBACK_VALUE,
    },
  ];

  if (!additionalParties || additionalParties.length === 0) {
    return fallbackParties;
  }

  return additionalParties.map((party, index) => {
    return {
      key: `${parseRiApplicationStringValue(party.email)}-${index}`,
      fullName: parseRiApplicationFullname(party.firstName, party.lastName),
      email: parseRiApplicationStringValue(party.email),
    };
  });
}

function parseRiApplicationBuildingUnit(buildingUnit: Maybe<string>): string {
  if (!buildingUnit) {
    return RI_DETAILED_APPLICATION_FALLBACK_VALUE;
  }

  // Remove following characters from the building unit in non-case-sensitive way
  // unit, Unit, UNIT, apt, Apt, APT, ste, Ste, STE, rm, Rm, RM, #, lot, Lot, LOT, Lbby, LBBY, lbby, LBBY
  const buildingUnitRegex = /\b(?:unit|apt|ste|rm|lot|lbby|#)\b/gi;

  const prunedBuildingUnit = buildingUnit.replace(buildingUnitRegex, '').trim();

  return `Unit ${prunedBuildingUnit}`;
}

function parseRiApplicationPolicyDate(date: Maybe<string>): string {
  if (!date) {
    return RI_DETAILED_APPLICATION_FALLBACK_VALUE;
  }

  const parsedDate = time(date, DateFormat.Calendar);

  return parsedDate.isValid()
    ? parsedDate.format(DateFormat.Us)
    : RI_DETAILED_APPLICATION_FALLBACK_VALUE;
}

export {
  parseRiApplicationDetails,
  parseRiApplicationDetailsAddress,
  parseRiApplicationCoverages,
  parseRiApplicationStringValue,
  parseRiApplicationCoverageValue,
  parseRiApplicationBuildingUnit,
  parseRiApplicationPolicyDate,
  parseRiApplicationFullname,
  parseRiApplicationAdditionalParties,
};
export type { ParsedRiApplicationAddress };
